<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view
      v-else-if="!subPermission.update || !subPermission.store"
    />
    <b-card :title="$t('g.basicInformations')" v-else>
      <!-- form -->
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="4">
              <b-form-group :label="$t('g.name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Lab Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Name -->
            <!-- Category -->
            <b-col md="4">
              <b-form-group :label="$t('g.category')" label-for="category">
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <select
                    class="form-control"
                    aria-label="categories"
                    v-model="form_data.category_id"
                  >
                    <option selected value>
                      {{ $t("g.Please_Select_Category") }}
                    </option>
                    <option
                      v-for="(categorie, index) in categories"
                      :key="index"
                      :value="categorie.id"
                    >
                      {{ categorie.name }}
                    </option>
                  </select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Category -->

            <!-- is_available -->
            <b-col md="4">
              <b-form-group :label="$t('g.status')">
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.is_available"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="available_options"
                    label="text"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** is_available -->

            <!-- language update -->
            <b-col md="4" v-if="isEdit">
              <b-form-group :label="$t('g.language')" label-for="Language">
                <validation-provider
                  #default="{ errors }"
                  name="Language"
                  rules="required"
                >
                  <select class="form-control" v-model="form_data.lang">
                    <option selected value>
                      {{ $t("g.Please_Select_Language") }}
                    </option>
                    <option
                      v-for="(lang, index) in lang_options"
                      :key="index"
                      :value="lang.value"
                    >
                      {{ lang.text }}
                    </option>
                  </select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BFormFile,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";
import { required } from "@validations";
//   import pagesData from "./pagesData";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    BSpinner,
    Loading,
    Error,
    BFormTextarea,
    BFormCheckbox,
    ContentNotView,
    BFormSelect,
  },
  data() {
    return {
      form_data: {
        name: null,
        category_id: null,
        is_available: false,
        lang: null,
      },
      lang_options: [
        { value: "ar", text: this.$t("g.Arabic") },
        { value: "en", text: this.$t("g.English") },
      ],
      available_options: [
        {
          value: null,
          text: this.$t("g.Please_Select_Status"),
          disabled: true,
        },
        {
          value: 1,
          text: this.$t("g.available"),
        },
        {
          value: 0,
          text: this.$t("g.notAvailable"),
        },
      ],
      labId: null,
      categories: null,
      isEdit: false,
      isLoading: true,
      isError: null,
      currentId: null,
      pageName: null,
      routePageName: null,
      subPermission: {},
    };
  },

  watch: {
    $route(to, from) {
      console.log("test");
      if (to.name === "AddLab") {
        this.isEdit = false;
        this.clearForm();
      } else if (to.name === "EditLab") {
        this.isEdit = true;
      }
    },
  },

  beforeMount() {
    if (this.$route.params.id) {
      this.labId = this.$route.params.id;
      this.getLab(this.labId);
      this.isEdit = true;
    }
    this.getCategories();
  },

  methods: {
    getCategories() {
      this.$http
        .get("admin/categories")
        .then((res) => {
          if (res.status === 200) {
            this.categories = res.data.data;
            setTimeout(() => {
              this.isLoading = false;
              this.isError = false;
              store.dispatch("GET_PERMISSION", "labs");
              this.subPermission = store.state.permissions.sub;
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearForm() {
      for (const i in this.form_data) {
        this.form_data[i] = null;
      }
    },
    getLab(id) {
      this.$http
        .get(`admin/labs/${id}`, {
          headers: {
            "x-locale": localStorage.getItem("lang"),
          },
        })
        .then((res) => {
          this.form_data.name = res.data.data.name;
          this.form_data.category_id = res.data.data.category.id;
          this.form_data.is_available = Number(res.data.data.is_available);
          this.form_data.lang = res.data.data.lang;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    // Send the modified data
    submitEdit(id) {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();

          for (let item in this.form_data) {
            formData.append(item, this.form_data[item.toString()]);
          }

          // call Backend
          this.$http
            .post(`admin/labs/${id}`, formData, { params: { _method: "put" } })
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "ListOfLabs" });
              }, 500);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit new lab
    submitNewAdditon() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();
          for (let item in this.form_data) {
            formData.append(item, this.form_data[item.toString()]);
          }
          this.$http
            .post("admin/labs", formData)
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "ListOfLabs" });
              }, 1000);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit Function
    validationForm() {
      this.form_data.is_available = Number(this.form_data.is_available);
      if (this.isEdit) {
        this.submitEdit(this.labId);
      } else {
        delete this.form_data.lang;
        this.submitNewAdditon();
      }
    },
  },
};
</script>
<style lang="scss">
.loading-div {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-box {
  label,
  div {
    display: inline-block !important;
  }

  label {
    margin: 0 5px;
  }
}
</style>
